import React from "react";
import Image from "next/image";
import { XOutlineIcon } from "@gonoodle/gn-universe-ui";

import { URLS } from "../../constants";

function OnMasqueradingMessage() {
  const [masqueradingCookie, setMasqueradingCookie] = React.useState("");

  React.useEffect(() => {
    const cookieMasquerade = document.cookie
      .split(";")
      .find((cookie) => cookie.trim().startsWith("masquerading="));
    if (cookieMasquerade) {
      setMasqueradingCookie(
        decodeURIComponent(decodeURIComponent(cookieMasquerade.split("=")[1])),
      );
    }
  }, []);
  const [messageVisible, setMessageVisible] = React.useState(true);

  const closeBanner = () => {
    setMessageVisible(false);
  };

  return (
    masqueradingCookie &&
    messageVisible && (
      <section className="gn-text-sm p-sm md:py-sm md:px-md flex items-center justify-end bg-yellow-600 text-white">
        <span className="text-left lg:text-right md:items-center md:pr-md flex flex-col md:flex-row items-start justify-center flex-1 space-x-2">
          <span>{`Currently masquerading as ${masqueradingCookie}`}</span>
          <Image
            height="18"
            width="18"
            alt="This looks suspicious!"
            src={`${URLS.GN_ASSETS_BASE_URL}/web_public/images/simpsons-dog.gif`}
          />
        </span>
        <button onClick={closeBanner}>
          <span className="sr-only">Close banner</span>

          <XOutlineIcon className="w-6 h-6 ml-4" />
        </button>
      </section>
    )
  );
}

export default OnMasqueradingMessage;
